import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useSearchParams, useLocation, Outlet } from "react-router-dom";
import MaintenanceBanner from "./components/Maintenance/MaintenanceBanner";
import MaintenancePopUp from "./components/Maintenance/MaintenancePopUp";
import { Profile } from "./types/profile";
import env from "./env";
import { auth } from "./api/auth";
import DevTools from "./components/DevTools/DevTools";
import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import useAnalytics from "./hooks/useAnalytics";
import "./styles/app.scss";
import CookieJar from "./lib/cookieJar";
import { endpoints } from "./api/http";
import { ProfileContext } from "./context/ProfileContext";
import useProfile from "./hooks/useProfile";
import useCommonStore from "@/store/hooks/useCommonStore";
import { storage } from "@/api/storage";
import Idle from "./components/Idle/Idle";
import useWebsocketEvent from "./hooks/useWebsocketEvent";
import Toast from "@ingka/toast";
import { SKAPA_PREFIX } from "./lib/constants";
import Loader from "@/components/Loaders/Loaders";
import { ConfigContext } from "./context/ConfigContext";
import useServerRequest from "./hooks/useServerRequest";
import NoConsent from "./components/Consent/NoConsent";
import { Role } from "@packages/common/user";
import ModalStack from "./components/Modals/ModalStack";
import SurveyPopup from "./components/Survey/SurveyPopup";
import DateTime from "@ingka-group-digital/lib-date-time";
import { NotAuthorizedError, AuthError } from "./types/error";
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,

            // assume data becomes stale in 1 min
            staleTime: 60 * 1000,

            // 5 min cache (cache should be higher than stale limit)
            cacheTime: 60 * 1000 * 5,
        },
    },
});

const DashboardHome = lazy(() => import("./components/Dashboard/DashboardHome"));
const CoworkerHome = lazy(() => import("./components/Coworker/CoworkerHome"));

const configureLocale = (lng: string) => {
    const locale = lng === "en-US" ? "en" : lng;
    const loadPath = "/assets/locales/{{lng}}.json";
    DateTime.config({ locale });

    return i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng: locale,
            fallbackLng: ["en"],
            load: "currentOnly",
            backend: { loadPath },
            debug: false,
            interpolation: {
                escapeValue: false,
                formatSeparator: ",",
            },
            react: {
                useSuspense: true,
            },

            saveMissing: true,
            missingKeyHandler: (lng, _namespace, key) => log.debug(`%${lng.join("/").toUpperCase()} key missing%`, key),
        });
};

const RoutesWrapper = () => {
    const profile = useProfile();
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    useAnalytics(profile);

    const wsEvent = useWebsocketEvent("refresh");

    const queryClient = useQueryClient();

    useEffect(() => {
        if (wsEvent) {
            setShowRefreshButton(true);
        }
    }, [wsEvent]);

    const setDevToolsVisible = useCommonStore(({ setDevToolsVisible }) => setDevToolsVisible);
    const isDevToolsVisible = useCommonStore(({ isDevToolsVisible }) => isDevToolsVisible);
    useEffect(() => {
        storage.get("showDevTools", true).then((showDevTools) => {
            storage.set("showDevTools", showDevTools);
            setDevToolsVisible(!!showDevTools);
        });
    }, []);
    const { data: config = {} } = useServerRequest("configs");

    return (
        <ConfigContext.Provider value={config}>
            <Suspense fallback={<Loader />}>
                <ModalStack>
                    <Idle />
                    {profile && !profile.gdprConsent && <NoConsent />}
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <>
                                    <Toast
                                        text={t("availability.availabilityWasUpdated")}
                                        isOpen={showRefreshButton}
                                        actionButtonText={t("refresh")}
                                        actionClick={() => {
                                            queryClient.invalidateQueries();
                                            setShowRefreshButton(false);
                                        }}
                                        onCloseRequest={() => {
                                            setShowRefreshButton(false);
                                        }}
                                        ariaLabelCloseBtn={t("dismissNotification")}
                                        prefix={SKAPA_PREFIX}
                                    />
                                    {(profile?.role === Role.DEV || profile?.developer) && isDevToolsVisible && <DevTools />}
                                    <SurveyPopup />
                                    <MaintenanceBanner />
                                    <MaintenancePopUp />
                                    <Outlet />
                                </>
                            }
                        >
                            {profile?.role !== Role.COWORKER && <Route path="dashboard/*" element={<DashboardHome />} />}
                            <Route path="coworker/*" element={<CoworkerHome />} />

                            <Route path="*" element={<ErrorPage title="404" description="Requested resource was not found" />} />
                        </Route>
                    </Routes>
                </ModalStack>
            </Suspense>
        </ConfigContext.Provider>
    );
};

const App = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [transReady, setTransReady] = useState(false);
    const [pendingAuth, setPendingAuth] = useState(false);
    const [profile, setProfile] = useState<Profile | null>(null);
    const [error, setError] = useState<unknown | null>(null);
    const [inProgress, setInProgress] = useState(true);

    const fetchProfile = async () => {
        try {
            log.info("loading profile...");
            const [info, pictureUrl] = await Promise.all([endpoints.request("users/profile"), auth.getProfilePicture()]);

            Sentry.setUser({ id: info.id });
            return Object.assign(Profile.prototype, {
                ...info,
                pictureUrl,
            });
        } catch (error) {
            setError(error);
        }
    };

    function redirectUser(pathname: string, isCoworker: boolean) {
        if (pathname === `/`) {
            return isCoworker ? `/coworker` : `/dashboard`;
        }

        if (isCoworker && pathname.startsWith("/dashboard")) {
            return `/coworker`;
        }

        return pathname;
    }

    const init = async () => {
        try {
            const user = await auth.login();
            if (!user) {
                return setError("We are sorry, but you do not have access to MIA");
            }

            const profile = await fetchProfile();

            log.info("i18n init");
            const oppJar = new CookieJar({ name: "opp" });
            const language = oppJar.get("lang", profile?.language ?? "en-US");

            configureLocale(language)
                .then(() => setTransReady(true))
                .catch(log.error);

            // redirect logic
            const isCoworker = profile.role === Role.COWORKER;
            const pathname = location.pathname;

            let redirect = redirectUser(pathname, isCoworker);
            // default location
            setProfile(profile);
            navigate({ pathname: redirect, search: searchParams.toString() });
        } catch (err) {
            log.error(err);
            setError("Something went wrong");
        } finally {
            setInProgress(false);
        }
    };

    useEffect(() => {
        init();
    }, []);

    if (error) {
        throw error instanceof Error ? new AuthError(error.message) : error;
    }

    if (!transReady || !profile || inProgress) {
        return <Loader when={!transReady || !profile || inProgress} />;
    }

    //Restrict access to non-developers in PPE and DEV environments
    if (!(profile.role === Role.DEV || !!profile.developer) && (env.is("ppe") || env.is("dev"))) {
        throw error instanceof Error ? new NotAuthorizedError("Access restricted environment") : error;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ProfileContext.Provider value={profile}>
                <RoutesWrapper />
            </ProfileContext.Provider>
        </QueryClientProvider>
    );
};

export default App;
